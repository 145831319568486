<template>
  <div class="test">
    <v-btn @click="close()">destroy</v-btn>
    test1_1
    <date-picker-menu
      v-model="targetDate"
      :label="$t('t.TargetDate')"
      :min="moment().format('YYYY-MM-DD')"
    />

  </div>
</template>

<script>
export default {
  components: {
    datePickerMenu: () => import('@/components/date-picker-menu')
  },
  data () {
    return {
      targetDate: null

    }
  },
  mounted () {

  },
  methods: {
    close () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  }

}
</script>

<style lang="stylus" scoped>
.test
  display flex
  flex-grow 1
  overflow hidden
  flex-direction column
</style>
